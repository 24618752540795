@if (isMobile$ | async) {
	<app-navi-mobile
		[homepageRoute]="homepageRoute"
		[menuItems]="activeMenuItems()"
		[favoriteCount]="favoriteService.count()"
		(sideActions)="routerSideActions($event)"
		(moveToSubItem)="moveToSubItem($event.menuItem, $event.groupName)"
		(emitShowFavorite)="showFavorite()"
	></app-navi-mobile>
} @else {
	<app-navi-desktop
		[menuItems]="activeMenuItems()"
		[favoriteCount]="favoriteService.count()"
		(moveToSubItem)="moveToSubItem($event.menuItem, $event.groupName)"
		[homepageRoute]="homepageRoute"
		(emitLogOut)="logout()"
		(sideActions)="routerSideActions($event)"
		(emitShowFavorite)="showFavorite()"
	></app-navi-desktop>
}
