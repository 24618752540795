import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from '@/new-navi/navi.api.service';

@Component({
	selector: 'app-navi-mobile',
	standalone: true,
	imports: [MatIcon, NgOptimizedImage, RouterLink],
	templateUrl: './mobile.component.html',
	styleUrl: './mobile.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('sidebarTrigger', [
			state('open', style({ transform: 'translateX(0%)' })),
			state('close', style({ transform: 'translateX(-100%)' })),
			transition('open => close', [animate('300ms ease-in')]),
			transition('close => open', [animate('300ms ease-out')]),
		]),
	],
})
export class MobileComponent {
	readonly menuItems = input.required<MenuItem[]>();
	readonly homepageRoute = input.required<string>();
	readonly favoriteCount = input.required<number>();

	readonly sideActions = output<MouseEvent>();
	readonly moveToSubItem = output<{ menuItem: MenuItem; groupName: string }>();
	readonly emitShowFavorite = output<void>();

	readonly showSidebar = signal(false);
	readonly expandedMenu = signal<number[]>([]);
	readonly expandedMenuId = signal<number>(null);

	showSubMenu(id: number): void {
		this.expandedMenuId.set(this.expandedMenu().includes(id) ? null : id);
		this.expandedMenu.set(
			this.expandedMenu().includes(id)
				? this.expandedMenu().filter((menuId) => menuId !== id)
				: [...this.expandedMenu(), id],
		);
	}

	routerSideActions(event: MouseEvent): void {
		this.showSidebar.set(false);
		this.sideActions.emit(event);
	}

	showFavorite(): void {
		this.emitShowFavorite.emit();
	}
}
