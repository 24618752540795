import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthBagService } from '@/auth/auth-bag.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private readonly auth: AuthBagService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const authReq = request.clone({
			setHeaders: this.httpHeaders,
		});

		return next.handle(authReq);
	}

	private get httpHeaders() {
		if (!this.auth.tokenGet()) {
			return {
				Accept: '*/*',
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
			};
		}
		return {
			Accept: '*/*',
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
			Authorization: 'Bearer ' + this.auth.tokenGet(),
		};
	}
}
