<nav
	(mouseleave)="triggerMask('hide')"
	[ngClass]="{ 'shadow-navi ': shouldAddShadow() }"
	class="flex w-full items-center justify-between py-4 bg-white text-mainColor font-bold relative z-[999]"
>
	<div class="px-12 flex justify-center">
		<a [routerLink]="homepageRoute()" (click)="sideActions.emit($event)"
			><img ngSrc="/assets/logo.webp" alt="OSKAR" height="60" width="208"
		/></a>
	</div>
	<div>
		<ul class="flex justify-center cursor-default">
			@for (item of menuItems(); track item.id) {
				<li
					(mouseenter)="triggerMask('show', item.childMenus, item.name)"
					class="px-6 flex justify-center items-center"
					[ngClass]="{
						'cursor-pointer': !!item.link,
					}"
				>
					<a
						class="flex items-center justify-center"
						(click)="moveToSubItem.emit({ menuItem: item, groupName: item.name })"
						><p>{{ item.anchor }}</p>
						@if (item.childMenus.length) {
							<i class="fas fa-chevron-down ms-2"></i>
						}
					</a>
				</li>
			}
		</ul>
	</div>

	<div class="flex justify-center px-12 whitespace-nowrap">
		@let isLoggedIn = isLoggedIn$ | async;
		<ng-container
			*ngTemplateOutlet="
				naviIconSection;
				context: {
					iconClass: 'far fa-heart',
					clickAction: showFavorite.bind(this),
					title: 'Ulubione',
					showBadge: true,
					badgeValue: favoriteCount(),
				}
			"
		></ng-container>

		<ng-container
			*ngTemplateOutlet="
				naviIconSection;
				context: {
					iconClass: 'fas fa-envelope',
					clickAction: showContact.bind(this),
					title: 'Skontaktuj się z nami',
				}
			"
		></ng-container>

		@if (isLoggedIn) {
			<ng-container
				*ngTemplateOutlet="
					naviIconSection;
					context: {
						iconClass: 'fas fa-sign-out-alt',
						clickAction: logout.bind(this),
						title: 'Wyloguj się',
					}
				"
			></ng-container>
		}

		<ng-template
			#naviIconSection
			let-iconClass="iconClass"
			let-clickAction="clickAction"
			let-title="title"
			let-showBadge="showBadge"
			let-badgeValue="badgeValue"
		>
			<button
				[title]="title"
				(click)="clickAction()"
				class="w-14 h-14 flex items-center justify-center transition border-2 border-mainColor bg-white hover:bg-[#03438b0d] rounded-full mr-2 relative"
			>
				<i [class]="iconClass"></i>
				@if (!!showBadge) {
					<span
						class="absolute -right-1 -top-1 rounded-full text-sm bg-favorite text-white w-5 h-5 flex justify-center items-center text-center"
						>{{ badgeValue }}</span
					>
				}
			</button>
		</ng-template>
	</div>
	<div
		class="absolute top-full w-full bg-white z-40 shadow-navi px-12 py-4"
		[ngClass]="{
			hidden: !childToDisplay()?.length,
		}"
	>
		<div class="grid grid-cols-5 justify-center items-center justify-items-center">
			@for (child of childToDisplay(); track child.id) {
				<a
					[ngClass]="{
						'cursor-pointer': !!child.link,
					}"
					(click)="moveToSubItem.emit({ menuItem: child, groupName: groupName() })"
					class="w-fit p-3 hover:underline text-center"
					>{{ child.anchor }}</a
				>
			}
		</div>
	</div>
</nav>
