<div
	class="group my-3 mx-auto lg:visible relative w-[40vw] md:w-[80vw] lg:w-full lg:my-0 h-[160px] lg:h-auto rounded-[40px] overflow-hidden cursor-pointer"
>
	<a [routerLink]="['/wakacje-autokarem']" [queryParams]="{ locations: data.location.id }">
		<div
			*ngIf="data?.location?.multimedia?.length && data?.location?.multimedia[0]?.multimedia?.url"
			class="w-full h-full rounded-[40px] bg-center bg-cover md:group-hover:scale-110 overflow-hidden transition"
		>
			<img
				[ngSrc]="data.location.multimedia[0].multimedia.url | optimizedImage"
				fill
				loading="lazy"
				[alt]="data.location.name"
			/>
		</div>
		<div class="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.3)]"></div>
		<h1
			class="absolute bottom-1 uppercase lg:text-[45px] left-1/2 -translate-y-1/2 -translate-x-1/2 text-white text-lg font-bold drop-shadow-lg transition group-hover:text-accentColor"
		>
			{{ data.location.name }}
		</h1>
	</a>
</div>
