<div class="h-[100%] mx-0 my-auto overflow-hidden block">
	<swiper [config]="swiperConfig" class="h-full">
		<ng-template swiperSlide *ngFor="let image of images">
			<div class="background w-full h-full bg-no-repeat bg-cover bg-top text-center transition">
				<img
					#bannerCarouselImage
					alt="swiper image"
					[attr.path]="image.path"
					[ngSrc]="image.path | optimizedImage"
					priority
					height="300"
					width="1920"
					class="object-cover h-full w-full relative"
				/>
				<app-button type="box-button" class="absolute mx-auto left-0 right-0 w-[80%] lg:w-1/6 top-3/4">
					<a [href]="image.url" class="flex items-center justify-center">
						Zobacz oferty
						<i class="ps-3 fa fa-chevron-right"></i>
					</a>
				</app-button>
			</div>
		</ng-template>
	</swiper>
</div>
