<div class="relative h-[160px] w-[160px] bg-auto bg-center mx-auto">
	<a [href]="'../oferta/' + data.productId + '?' + getUrlParams()" (click)="handleButtonClick($event)">
		<ng-container *ngIf="data.image; else noImage">
			<img
				[ngSrc]="data.image | optimizedImage"
				height="160"
				width="160"
				loading="lazy"
				[alt]="data.title"
				class="rounded-[40px] h-full"
			/>
		</ng-container>
		<ng-template #noImage>
			<p>Brak zdjęcia</p>
		</ng-template>
		<div
			class="absolute bg-white bg-opacity-70 bottom-0 left-0 w-full text-sm flex justify-center flex-col rounded-b-[40px] p-4 text-mainColor font-extrabold"
		>
			<h1 class="text-left w-9/10 md:whitespace-normal md:overflow-visible truncate">
				{{ data.title }}
			</h1>
			<div class="flex flex-row justify-between w-9/10">
				<p class="text-xs md:text-base">od {{ data.price | price }}</p>
			</div>
		</div>
	</a>
</div>
