import { createReducer, on } from '@ngrx/store';
import * as currentOfferActions from './current-offer.actions';
import {
	MatProduct,
	OptionalTrip,
	DescriptionTemplate,
	SellTerm,
	MatPromotion,
	PriceSettings,
	SellTransport,
	Accommodation,
	ROOM_ONE,
	FilterValuesByRoom,
	DEFAULT_STOP,
	CountRoomType,
	SellTransportStatistics,
} from '@/_store/current-offer/current-offer.types';
import { Descriptions, Location, LocationDescriptions, OfferService } from '@/offer/offer.model';
import { findFirstDigit } from '@/_utils/strings/find-first-digit';
import { findBestRoom, roomTypesAmount } from './current-offer.utils';
import { deepClone } from '@/_utils/objects/deep-clone';

export interface CurrentOfferState {
	product: MatProduct;
	optionalTrip: OptionalTrip[];
	location: Location;
	descriptions: Descriptions[];
	descriptionTemplates: DescriptionTemplate[];
	sellTerms: SellTerm[];
	sellTransport: SellTransport[];
	promotions: MatPromotion[];
	filterValues: FilterValuesByRoom;
	editedRoom: NonNullable<string>;
	priceSettings: PriceSettings;
	accommodation: Accommodation[];
	locations: LocationDescriptions[];
	allPromotions: MatPromotion[];
	roomTypesCount: CountRoomType;
	sellTransportStatistics: SellTransportStatistics;
	multiRoomMode: boolean;
	offerService: OfferService[];
}

export const initialState: CurrentOfferState = {
	product: null,
	optionalTrip: null,
	location: null,
	descriptions: null,
	descriptionTemplates: null,
	sellTerms: null,
	sellTransport: null,
	promotions: null,
	filterValues: {},
	editedRoom: ROOM_ONE,
	priceSettings: null,
	accommodation: null,
	locations: null,
	allPromotions: null,
	roomTypesCount: null,
	sellTransportStatistics: null,
	multiRoomMode: false,
	offerService: [],
};

export const currentOfferReducer = createReducer(
	initialState,
	on(currentOfferActions.clearCurrentOffer, (state) => ({
		...initialState,
		descriptions: state.descriptions, // we want to leave only description and description template which are downloaded once
		descriptionTemplates: state.descriptionTemplates,
	})),
	on(currentOfferActions.fetchMatProductSuccess, (state, { product }) => ({
		...state,
		product,
	})),
	on(currentOfferActions.fetchOptionalTripSuccess, (state, { optionalTrip }) => ({
		...state,
		optionalTrip,
	})),
	on(currentOfferActions.fetchLocationsSuccess, (state, { locations }) => ({
		...state,
		locations,
	})),
	on(currentOfferActions.fetchDescriptionSuccess, (state, { descriptions }) => ({
		...state,
		descriptions,
	})),
	on(currentOfferActions.fetchDescriptionTemplateSuccess, (state, { descriptionTemplates }) => ({
		...state,
		descriptionTemplates,
	})),
	on(currentOfferActions.fetchSellTermSuccess, (state, { sellTerms }) => ({
		...state,
		sellTerms,
	})),
	on(currentOfferActions.fetchSellTransportSuccess, (state, { sellTransport }) => {
		let transport = state.filterValues[state.editedRoom]?.transport;
		const defaultStop = sellTransport.find((value) => value?.location === DEFAULT_STOP);
		if (transport && transport.ownTransport && defaultStop) {
			transport = {
				ownTransport: false,
				stop: defaultStop,
			};
		} else if (transport && transport.stop) {
			transport = {
				ownTransport: false,
				stop: sellTransport.find((t) => t.id === transport.stop.id),
			};
		}

		return {
			...state,
			sellTransport,
			filterValues: {
				...state.filterValues,
				[state.editedRoom]: {
					...state.filterValues[state.editedRoom],
					transport,
				},
			},
		};
	}),
	on(currentOfferActions.fetchAccommodationSuccess, (state, { accommodation }) => {
		const filterValues = deepClone<FilterValuesByRoom>(state.filterValues);

		Object.entries(filterValues).forEach(([key, value]) => {
			const isFilterAccInNewArr = accommodation.some((a) => a.id === value.roomType?.id);
			const filteredAccommodation = accommodation.filter(
				(a) => a.roomTypeCount > (roomTypesAmount(state.filterValues, state.editedRoom, a.name) as number),
			);

			if (!isFilterAccInNewArr) {
				const room =
					findBestRoom(filteredAccommodation, filterValues[key].participants) ?? filteredAccommodation[0];
				filterValues[key].roomType = room;
			}

			if (!filterValues[key].roomType) {
				return;
			}

			if (
				filterValues[key].participants.adults + filterValues[key].participants.children >
				filterValues[key].roomType.adults + filterValues[key].roomType.children
			) {
				if (filterValues[key].participants.adults > filterValues[key].roomType.adults) {
					filterValues[key].participants.adults = filterValues[key].roomType.adults;
				}

				if (filterValues[key].participants.children > filterValues[key].roomType.children) {
					filterValues[key].participants.children = filterValues[key].roomType.children;
				}
			}
		});

		return {
			...state,
			filterValues,
			accommodation,
		};
	}),
	on(currentOfferActions.fetchPriceSettingsSuccess, (state, { priceSettings }) => {
		const { editedRoom } = state;
		const activeFilterValue = state.filterValues[editedRoom];
		const newPriceSetting =
			priceSettings.activePriceSettingBoards.find(
				(priceSetting) => priceSetting.board.id === activeFilterValue?.board?.id,
			) ?? priceSettings.activePriceSettingBoards[0];

		if (!newPriceSetting?.board) {
			return {
				...state,
			};
		}

		return {
			...state,
			priceSettings,
			filterValues: {
				...state.filterValues,
				[editedRoom]: {
					...activeFilterValue,
					board: {
						id: newPriceSetting.board.id,
						name: newPriceSetting.board.name,
						price: newPriceSetting.price,
					},
				},
			},
		};
	}),
	on(currentOfferActions.fetchOfferServiceSuccess, (state, { offerService }) => ({
		...state,
		offerService,
	})),
	on(currentOfferActions.fetchPromotionsSuccess, (state, { promotions, allPromotions }) => ({
		...state,
		promotions,
		allPromotions,
	})),
	on(currentOfferActions.fetchCountRoomTypesSuccess, (state, { roomTypesCount }) => ({
		...state,
		roomTypesCount,
	})),
	on(currentOfferActions.fetchSellTransportStatisticsSuccess, (state, { sellTransportStatistics }) => ({
		...state,
		sellTransportStatistics,
	})),
	on(currentOfferActions.updateFilterTerm, (state, { dateRange }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				dateRange,
			},
		},
	})),
	on(currentOfferActions.updateFilterChildrenBirthday, (state, { childrenBirthday }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				participants: {
					...state.filterValues[state.editedRoom].participants,
					childrenBirthday:
						state.filterValues[state.editedRoom].participants.children === 0
							? []
							: childrenBirthday.filter((b) => !!b),
				},
			},
		},
	})),
	on(currentOfferActions.updateFilterParticipants, (state, { participants }) => {
		const room = findBestRoom(state.accommodation, participants);
		const activeFilterRoom = state?.filterValues[state?.editedRoom]?.roomType ?? null;
		const activeRoomType = state?.accommodation?.find((a) => a.id === activeFilterRoom?.id);
		const shouldApplyNewRoom =
			activeRoomType?.adults + activeRoomType?.children - 1 <= participants?.adults + participants?.children;
		const howManyRoomType = roomTypesAmount(state.filterValues, state.editedRoom, room?.name) as number;

		return {
			...state,
			filterValues: {
				...state.filterValues,
				[state.editedRoom]: {
					...state.filterValues[state.editedRoom],
					participants: {
						...(state.filterValues[state.editedRoom]?.participants ?? {}),
						...participants,
					},
					roomType:
						room && shouldApplyNewRoom
							? howManyRoomType < room.roomTypeCount
								? {
										id: room.id,
										name: room.name,
										fee: room.fee,
										code: room.code,
										adults: room.adults,
										children: room.children,
										roomTypeCount: room.roomTypeCount,
									}
								: null
							: activeFilterRoom,
				},
			},
		};
	}),
	on(currentOfferActions.updateFilterRoomType, (state, { roomType }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				roomType,
			},
		},
	})),
	on(currentOfferActions.updateFilterBoard, (state, { board }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				board,
			},
		},
	})),
	on(currentOfferActions.updateFilterStop, (state, { transport }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				transport,
			},
		},
	})),
	on(currentOfferActions.fetchSellTransportError, (state) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				transport: {
					ownTransport: true,
				},
			},
		},
	})),
	on(currentOfferActions.updateFilterPromotion, (state, { promotion }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				promotion,
			},
		},
	})),
	on(currentOfferActions.addNewRoom, (state, { roomTypeId }) => {
		const numberOfRooms = Object.keys(state.filterValues).length;
		const room =
			state.accommodation.find((a) => a.id === roomTypeId) ??
			state.filterValues[`Pokój ${numberOfRooms}`].roomType;
		const participants = room
			? { adults: room.adults, children: room.children }
			: state.filterValues[`Pokój ${numberOfRooms}`].participants;
		const key = `Pokój ${numberOfRooms + 1}`;
		const howManyRoomType = roomTypesAmount(state.filterValues, state.editedRoom, room?.name) as number;
		return {
			...state,
			filterValues: {
				...state.filterValues,
				[key]: {
					...state.filterValues[`Pokój ${numberOfRooms}`],
					participants: {
						...participants,
						childrenBirthday: [],
					},
					roomType: howManyRoomType < room?.roomTypeCount ? room : null,
				},
			},
			editedRoom: key,
		};
	}),
	on(currentOfferActions.updateEditedRoom, (state, { editedRoom }) => ({
		...state,
		editedRoom,
	})),
	on(currentOfferActions.removeRoom, (state, { roomName }) => {
		const removedRoomNumber = findFirstDigit(roomName);
		const editedRoomNumber = findFirstDigit(state.editedRoom);
		return {
			...state,
			filterValues: Object.entries(state.filterValues).reduce((acc: FilterValuesByRoom, [key, value]) => {
				if (key !== roomName && state.filterValues) {
					acc[`Pokój ${Object.keys(acc).length + 1}`] = value;
				}
				return acc;
			}, {}),
			editedRoom:
				state.editedRoom === roomName
					? `Pokój ${removedRoomNumber === 1 ? 1 : removedRoomNumber - 1}`
					: removedRoomNumber < editedRoomNumber
						? `Pokój ${editedRoomNumber - 1}`
						: state.editedRoom,
		};
	}),
	on(currentOfferActions.addNightsAmountOption, (state, { nightsAmountFilter }) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				nightsAmountFilter,
			},
		},
	})),
	on(currentOfferActions.removeNightsAmountOption, (state) => ({
		...state,
		filterValues: {
			...state.filterValues,
			[state.editedRoom]: {
				...state.filterValues[state.editedRoom],
				nightsAmountFilter: null,
			},
		},
	})),
	on(currentOfferActions.setMultiRoomMode, (state, { value }) => ({
		...state,
		filterValues:
			value === false
				? {
						[ROOM_ONE]: state.filterValues[ROOM_ONE],
					}
				: state.filterValues,
		multiRoomMode: value,
		editedRoom: ROOM_ONE,
	})),
);
